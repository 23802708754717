import React from "react";
import { Link } from "react-router-dom";

export const FAQ_LIST = [
  {
    title: "What is the difference between buying antique and vintage?",
    description: (
      <>
        Authentic antique finds are over 100 years old and are rare, as they are
        no longer produced. These antique treasures include those from the{" "}
        <Link to="/period/georgian-antiques-(1714-to-1837)">Georgian</Link>,{" "}
        <Link to="/period/victorian-(1837-1901)">Victorian</Link>, and{" "}
        <Link to="/period/edwardian-(1901-1910)">Edwardian</Link> eras. Vintage
        items are those aged 50 to 100 years old.
      </>
    ),
  },
  {
    title: "How do I know if antiques for sale are valuable?",
    description: (
      <>
        If you decide to buy antiques UK dealers are offering, look for small
        marks and signatures that are often placed in an obscure spot of the
        piece. It's also worth noting that every antique item is created
        according to the style of the <Link to="/period">period</Link> during
        which it was created, so you have to research about the period that
        interests you, especially when you buy antiques online.
      </>
    ),
  },
  {
    title: "What kinds of antiques are worth money?",
    description: (
      <>
        The items to start looking at in antiques stores are{" "}
        <Link to="/antique-furniture">furniture pieces</Link>, as some are
        extremely rare. Other antiques worth splurging on include{" "}
        <Link to="/antique-art">art</Link>,{" "}
        <Link to="/vintage-jewellery">jewellery</Link>, and{" "}
        <Link to="/antique-ceramics">ceramics</Link>, among others. A reputable
        antique website provides a price range for each antique for sale, giving
        you an idea of how lucrative this type of investment can be.
      </>
    ),
  },
  {
    title: "What are the most sought after antiques?",
    description: (
      <>
        UK antique collectors most frequently buy{" "}
        <Link to="/antique-furniture">antique furniture</Link>, silverware,{" "}
        <Link to="/vintage-watches">watches</Link>,{" "}
        <Link to="/antique-clocks">clocks</Link>,{" "}
        <Link to="/antique-mirrors">mirrors</Link>,{" "}
        <Link to="/antique-chairs">chairs</Link> and more. Should you decide to
        shop antiques online, UK dealers have to ensure the store or dealer that
        is selling antiques is an established one, to avoid ending up with
        counterfeit items.
      </>
    ),
  },
];
